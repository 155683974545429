/******************************************************************
       Page Header
******************************************************************/

.headerWrapper {
       // background-image:url("../../images/mySiteFleet_header.png");
       background-size:cover;
       padding:70px;

       @media(max-width:500px) {
              padding:25px;
       }

       .MuiPaper-root  {
              padding:25px;
              background-color:rgba(255, 255, 255, 0.8);

              h2 {
                     text-align: center;
                     text-transform: uppercase;
                     font-weight: bold;
                     font-size:40px;

                     @media(max-width:500px) {
                            font-size:25px;
                     }
              }

              p {
                     max-width: 70%;
                     margin: 0 auto;
                     text-align: center;

                     @media(max-width:500px) {
                            max-width:100%;
                     }
              }
       }
}

.page-title-container{
       color: $blue;
       font-size: 24px;
       font-weight: 700;
       display: flex;
       justify-content: space-between;
       width: 100%;
       @media(max-width:768px){
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 28px;
              font-weight: 900;
              text-align: center;
              margin:28px auto;
              text-transform: uppercase;
              color: black
       }

       .cost-work-plan-page-title {
              @media(min-width:768px) {
                     max-width: 80%;
              }
       }
}
.print-container{
       margin: 30px 2px;
       cursor: pointer;

       svg {
              position: relative;
              top:6px;
       }
       @media(max-width:768px){
             display: none;
       }
}
.print-text{
       font-size: 14px;
       margin: 10px;
       text-transform:uppercase;
}