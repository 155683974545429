/******************************************************************
	Page Buttons
******************************************************************/

.pageButtons {
       width:100%;

       .MuiGrid-root {
              display:flex;
              justify-content: center;
              padding:50px;

              @media(max-width:768px) {
                     padding:0px;
              }

              .MuiGrid-root {
                     .MuiGrid-item {

                            @media(max-width:768px) {
                                   width:100%;
                                   padding:12px 0;
                            }

                            a {
                                   background-color:#3277B9;
                                   color:#fff;
                                   padding:15px 30px;
                                   text-decoration: none;
                                   font-size:15px;
                                   font-weight: 600;

                                   @media(max-width:768px) {
                                          width:100%;
                                          text-align: center;
                                   }
                            }

                            &:nth-child(2), &:nth-child(3) {
                                   a {
                                          background-color: #fff;
                                          color:#3277B9;
                                          border:1px solid #3277B9;
                                   }
                            }
                     }
              }
       }
}