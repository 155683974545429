/******************************************************************
	Buttons
******************************************************************/

.MuiButtonGroup-root {
       margin:0 0 20px;
       box-shadow: 0px 7px 15px rgba(0,0,0,0.2) !important;

       .MuiButton-root {
              background-color: $blue;
              color:#fff;
              padding:15px 60px;
              text-transform: none;
              border-radius: 0px;
              border:none !important;

              &:hover {
                     background-color: #fff;
                     color:#2A2A2A;
                     box-shadow: none !important;
              }
       }
}