/******************************************************************
	Main SCSS
******************************************************************/
 
@import "vendor/normalize/normalize";
@import "global/variables";
@import "global/typography";

@import "components/modal";
@import "components/page-header";
@import "components/page-buttons";
@import "components/navigation";
@import "components/accordions";
@import "components/buttons";
@import "components/buttons";
@import "components/form-controls";
@import "components/filters";

@import "pages/pages";

@import "print/print";


$medium: 960px;
$large: 961px;

html {
       height: 100%;

       body {
              background-color: white;
              min-height: 100%;
       }
}

.App {
       .react-flow-content {
              background-color: #f0f3fc;
       }

       @media screen and (min-width: $large) {
              display: flex;
              min-height: 96vh;
              align-items: stretch;

              .navigation-before-content {                     
                     width: 280px;
                     box-sizing: border-box;
                     min-height: 99vh;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
              }

              .react-flow-content {
                     width: 100%; 
                     > div:first-child {
                            padding: 25px;
                     }
              }
       }

       @media screen and (max-width: $medium) {
              display: flex;
              flex-flow: row wrap;
              .navigation-before-content {
                     position: fixed;
                     width: 280px;
                     height: 100%;
                     // overflow-y: scroll;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
              }
              .mobile-navigation-bar {
                     display: flex;
                     align-items: center;
                     width: 100%;
                     background-color: #000000;
                     color: #ffffff;
                     .mobile-navigation-bar-title {
                            padding-left: 16px;
                     }
                     .mobile-navigation-bar-button {
                            margin-left: auto;
                     }
              }
              .react-flow-content {
                     width: 100%;
                     min-width: 360px;

                     > div:first-child {
                            padding: 25px;
                     }
              }
       }              
}


.tooltip-text {
      font-size: 1rem;
}

.tooltip.info-icon {
       padding: 0 4px;
       min-width: 24px;
}

.tooltip.reco-tooltip {
       background-color: rgb(0, 0, 70);

       svg {
              fill: white;
              background-color: rgb(0, 0, 70);
       }
}

.tooltip.title-component-tooltip {
       svg {
              margin-top: -1rem;
       }
}
