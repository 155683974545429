/******************************************************************
	Home
******************************************************************/

.home {
       min-height: 100%;
       background-image: url("../../images/homepage_backgroundImage.png");
       background-size: cover;
       background-attachment: fixed;

       .homePage {
              .MuiContainer-root {
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: space-between;

                     .logo {
                            display: flex;
                            margin-bottom: 20px;
                            @media (max-width: 550px) {
                                   width: 100%;
                                   justify-content: space-between;
                            }

                            #leftSpace {
                                   max-width: 105px;
                                   margin-left: 40px;
                                   height: 135px;
                                   @media (max-width: 550px) {
                                          margin-left: 50px;
                                   }
                            }
                     }

                     .loginButtonAndModal {
                            @media (max-width: 550px) {
                                   display: flex;
                                   margin-bottom: 15px;
                                   width: 100%;
                                   justify-content: space-between;
                            }
                            .left-login-button {
                                   margin-right: 24px;
                            }

                            button {
                                   position: relative;
                                   top: 50%;
                                   transform: translateY(-50%);
                                   background-color: #3277b9;
                                   color: #fff;
                                   border: none;
                                   padding: 10px 20px;
                                   text-transform: uppercase;
                                   font-weight: 600;
                                   cursor: pointer;
                            }
                     }

                     .MuiBox-root {
                            width: 100%;
                            background-color: #fff;
                            padding: 25px 40px;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;

                            @media (max-width: 550px) {
                                   padding: 25px;
                            }

                            h2 {
                                   font-size: 48px;
                                   font-weight: 500;
                                   text-transform: uppercase;
                                   padding-right: 120px;

                                   @media (max-width: 768px) {
                                          font-size: 28px;
                                   }

                                   @media (max-width: 600px) {
                                          font-size: 24px;
                                          padding-right: 0px;
                                   }

                                   b {
                                          color: #000046;
                                   }
                            }

                            .MuiBox-root {
                                   width: auto;
                                   padding: 0;

                                   ul {
                                          position: relative;
                                          top: 15px;
                                          padding: 0;
                                          margin: 0;

                                          li {
                                                 display: inline-block;
                                                 font-size: 16px;
                                                 font-weight: 600;
                                                 margin-right: 20px;

                                                 @media (max-width: 768px) {
                                                        display: block;
                                                        margin: 0 20px 15px 0;
                                                 }

                                                 &:last-child {
                                                        margin-right: 0px;
                                                 }

                                                 &:before {
                                                        position: relative;
                                                        top: 4px;
                                                        margin-right: 5px;
                                                        content: "";
                                                        width: 20px;
                                                        height: 20px;
                                                        display: inline-block;
                                                        background-size: 20px auto;
                                                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23F7941D'/%3E%3Cpath d='M10.3984 15.8242L7.13672 12.582C7.04557 12.4909 7 12.3737 7 12.2305C7 12.0872 7.04557 11.97 7.13672 11.8789L7.85937 11.1758C7.95052 11.0716 8.06445 11.0195 8.20117 11.0195C8.33789 11.0195 8.45833 11.0716 8.5625 11.1758L10.75 13.3633L15.4375 8.67578C15.5416 8.57161 15.6621 8.51953 15.7988 8.51953C15.9355 8.51953 16.0495 8.57161 16.1406 8.67578L16.8633 9.3789C16.9544 9.47005 17 9.58724 17 9.73047C17 9.87369 16.9544 9.99088 16.8633 10.082L11.1016 15.8242C11.0104 15.9284 10.8932 15.9805 10.75 15.9805C10.6068 15.9805 10.4896 15.9284 10.3984 15.8242Z' fill='white'/%3E%3C/svg%3E%0A");
                                                 }
                                          }
                                   }
                            }
                     }

                     .MuiGrid-root {
                            width: 100%;
                            background-color: #fff;
                            margin: 0;
                            padding: 0px 40px 20px;

                            @media (max-width: 768px) {
                                   flex-direction: column;
                                   padding: 0 30px 20px;
                            }

                            .MuiGrid-item {
                                   padding: 20px;

                                   @media (max-width: 768px) {
                                          padding: 20px 0;
                                   }

                                   .MuiPaper-root {
                                          display: flex;
                                          flex-wrap: wrap;
                                          // flex-direction: column;
                                          // justify-content: space-between;
                                          position: relative;
                                          padding: 25px;
                                          box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
                                          min-height: 380px;
                                          align-content: space-between;

                                          img {
                                                 display: block;
                                                 margin: 0 auto 25px;
                                          }

                                          .homepage_step_text {
                                                 width: 100%;
                                                 text-transform: uppercase;
                                                 font-size: 12px;
                                                 color: #373a3c;
                                                 margin: 0 0 10px;
                                          }

                                          .homepage_step_title {
                                                 font-size: 22px;
                                          }

                                          p.homepage_step_content {
                                                 font-size: 14px;
                                                 line-height: 1.5em;
                                                 color: #767676;
                                          }

                                          .cta_row {
                                                 display: flex;
                                                 width: 100%;
                                                 justify-content: space-between;

                                                 @media (max-width: 420px) {
                                                        flex-wrap: wrap;
                                                 }

                                                 a {
                                                        background-color: #3277b9;
                                                        color: #fff;
                                                        text-decoration: none;
                                                        padding: 10px 20px;
                                                        font-size: 14px;
                                                        font-weight: 600;
                                                        height: 14px;

                                                        @media (max-width: 420px) {
                                                               width: 100%;
                                                               text-align: center;
                                                               margin-bottom: 15px;
                                                        }

                                                        &:last-child {
                                                               @media (max-width: 420px) {
                                                                      margin-bottom: 0px;
                                                               }
                                                        }
                                                 }
                                          }
                                   }
                            }
                     }
              }
       }
}
