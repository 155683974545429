/******************************************************************
	Guided Planner
******************************************************************/

.guided-planner-app {
       padding: 0 !important;

       .headerWrapper {
              background-image: url("../../images/mySiteFleet_header.png");
       }

       .charge-finish-mobile {
              margin-top: -1rem;
       }

       .MuiButtonBase-root {
              background-color: #fff;

              span.MuiButton-label {
                     color: #000;
              }

              &.activeButton {
                     background-color: #3277b9;

                     span.MuiButton-label {
                            color: #fff;
                     }
              }
       }

       & .guided-planner > .MuiBox-root {
              padding: 25px;
       }

       .guided-planner {
              .MuiBox-root {
                     .station-size-dropdown {
                            label {
                                   font-size: 14px;
                                   color: rgb(98, 98, 98);
                            }
                     }       

                     .MuiBox-root {
                            &:nth-child(1) {
                                   .MuiBox-root {
                                          background-color: transparent;
                                          padding: 0;

                                          .MuiGrid-root {
                                                 padding: 0;
                                          }
                                   }

                                   p.MuiTypography-root {
                                          font-size: 14px;
                                          font-weight: bold;
                                          color: #626262;
                                          margin: 0 0 15px;
                                          padding: 0;
                                   }
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                   padding: 30px 30px 30px 30px;
                                   background-color: #fff;
                                   margin: 0 0 25px;

                                   @media (max-width: 768px) {
                                          padding: 20px;
                                   }

                                   .MuiBox-root {
                                          padding: 0;
                                   }

                                   .MuiTypography-body1 {
                                          padding-left: 0px;
                                          margin: 0 0 20px;
                                   }

                                   .fieldgroup_title {
                                          font-weight: bold;
                                          text-transform: uppercase;
                                          font-size: 24px;
                                   }

                                   .fieldgroup_subtitle {
                                          font-weight: 300;
                                          font-size: 14px;
                                          margin-left: 25px;
                                          color: #979797;
                                   }

                                   .MuiGrid-root {
                                          margin: 0 0 15px;

                                          @media(max-width:991px) {
                                                 margin-bottom: 0px;
                                          }

                                          &:last-child {
                                                 margin: 0;
                                          }
                                   }

                                   .MuiGrid-spacing-xs-2 {
                                          width: 100%;
                                          justify-content: flex-start;
                                   }

                                   .MuiFormControl-root {
                                          float: right;

                                          .MuiTypography-body1 {
                                                 padding: 0;

                                                 @media(max-width:768px) {
                                                        margin-bottom: 0;
                                                 }
                                          }

                                          .MuiFormControlLabel-root {
                                                 margin-right: 20px;

                                                 &:last-child {
                                                        margin-right: 0;
                                                 }
                                          }

                                          .MuiFormControlLabel-label.MuiTypography-body1 {
                                                 font-size: 14px;
                                                 color: #626262;
                                                 font-weight: bold;
                                                 padding: 0px 20px 0 0;
                                                 margin-bottom: 10px;
                                          }
                                   }
                            }

                            &:nth-child(3) {
                                   .MuiBox-root {
                                          .MuiGrid-root {
                                                 &:nth-child(3) {
                                                        .MuiTypography-root.MuiTypography-body1 {
                                                               padding: 0;
                                                               color: #626262;
                                                               font-size: 14px;
                                                               font-weight: bold;
                                                        }
                                                 }
                                          }
                                   }
                            }
                     }
              }
       }

       .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
              padding: 25px;
              margin: 0;
              width: 100% !important;

              .MuiPaper-root {
                     position: relative;
                     width: 50%;
                     background-color: #000046;
                     border-radius: 0px;

                     @media (max-width: 768px) {
                            width: 100%;
                     }

                     &.Recommendation_ev_yes,
                     &.Recommendation_h2_yes {
                            opacity: 1;
                            a {
                                   opacity: 1 !important;
                            }
                     }

                     &.Recommendation_h2_No,
                     &.Recommendation_ev_no {
                            span,
                            div {
                                   opacity: 0.6;
                            }
                            
                     &:nth-child(n) {
                            .MuiCardContent-root ul li {
                                   &:before {
                                          background-repeat: no-repeat;
                                          background-position: center center;
                                          background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.76605 0.233946C7.45403 -0.077982 6.94824 -0.077982 6.63622 0.233946L4.005 2.86516L1.37379 0.233946C1.06026 -0.0688653 0.561903 -0.0645347 0.253689 0.243679C-0.0545256 0.551894 -0.0588562 1.05026 0.243955 1.36378L2.87517 3.995L0.243955 6.62621C0.0362413 6.82683 -0.0470627 7.12391 0.0260611 7.40328C0.099185 7.68264 0.317357 7.90082 0.596723 7.97394C0.876088 8.04706 1.17317 7.96376 1.37379 7.75604L4.005 5.12483L6.63622 7.75604C6.94974 8.05886 7.44811 8.05453 7.75632 7.74631C8.06453 7.4381 8.06886 6.93974 7.76605 6.62621L5.13484 3.995L7.76605 1.36378C8.07798 1.05176 8.07798 0.545968 7.76605 0.233946Z' fill='white'/%3E%3C/svg%3E%0A");
                                   }
                            }
                     }
                     }

                     &:first-child {
                            &:after {
                                   position: absolute;
                                   top: 10%;
                                   right: 0;
                                   content: "";
                                   width: 1px;
                                   height: 80%;
                                   background-color: #ccc;
                                   display: block;

                                   @media (max-width: 768px) {
                                          display: none;
                                   }
                            }

                            .MuiCardContent-root {
                                   @media (max-width: 768px) {
                                          border-bottom: 1px solid #ccc;
                                   }
                            }
                     }


                     .MuiCardContent-root {
                            padding: 45px 80px;

                            @media (max-width: 768px) {
                                   padding: 45px 50px;
                                   max-width: 300px;
                                   margin: 0 auto;
                            }

                            span.MuiTypography-root {
                                   color: #fff;
                                   font-size: 20px;
                                   margin-bottom: 20px;
                                   display: block;
                            }

                            ul {
                                   padding-left: 15px;
                                   margin: 15px 0 30px;
                                   list-style-type: none;

                                   li {
                                          position: relative;
                                          color: #fff;
                                          font-size: 14px;
                                          margin: 0 0 10px;
                                          padding: 0 0 0 10px;

                                          &:before {
                                                 content: "";
                                                 width: 15px;
                                                 height: 15px;
                                                 display: block;
                                                 position: absolute;
                                                 left: -15px;
                                                 background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4937 4.75844C11.8383 4.41385 12.397 4.41385 12.7416 4.75844C13.0861 5.10302 13.0861 5.66169 12.7416 6.00627L7.44745 11.3004C7.10287 11.645 6.54419 11.645 6.19961 11.3004L3.25844 8.35921C2.91385 8.01463 2.91385 7.45596 3.25844 7.11138C3.60302 6.7668 4.16169 6.7668 4.50627 7.11138L6.82353 9.42864L11.4937 4.75844Z' fill='white'/%3E%3C/svg%3E%0A");
                                          }
                                   }
                            }

                            a {
                                   opacity: 0.6;

                                   &:hover {
                                          opacity: 0.7;
                                   }

                                   button {
                                          cursor: pointer;
                                          background-color: #f7941d;
                                          border: none;
                                          font-weight: 600;
                                          padding: 15px 20px;
                                          font-size: 12px;
                                   }
                            }
                     }
              }
       }
}
