/******************************************************************
	Accordions
******************************************************************/

.MuiAccordion-root {
       position: relative;
       padding:20px;

       &:after {
              width:100%;
              height:30px;
              position: absolute;
              top:0;
              right:0;
              opacity: 0;
              background: rgb(39,56,126);
              background: -moz-linear-gradient(180deg, rgba(39,56,126,0.12648809523809523) 0%, rgba(5,2,103,0) 100%);
              background: -webkit-linear-gradient(180deg, rgba(39,56,126,0.12648809523809523) 0%, rgba(5,2,103,0) 100%);
              background: linear-gradient(180deg, rgba(39,56,126,0.12648809523809523) 0%, rgba(5,2,103,0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#27387e",endColorstr="#050267",GradientType=1);
              transition: all 0.3;
       }

       &.Mui-expanded {
              margin: 0px !important;

              &:after {
                     opacity: 0.5;
              }
       }

       .MuiAccordionSummary-expandIcon {
              background-color: $blue;

              &:hover {
                     background-color: $blue;
              }

              svg.MuiSvgIcon-root {
                     fill:#fff;
              }
       }

       .MuiAccordionDetails-root {
              display:block;

              h1 {
                     font-weight: 400;
                     font-size:20px;
                     color:#000046;
              }

              p {
                     color:#000000;
                     font-size:16px;
                     line-height: 1.5em;
              }

              .MuiCard-root.tall-info-card {
                     padding-bottom: 30px;
              }

              .MuiCard-root {
                     height:90%;
                     border:1px solid #ccc;
                     box-shadow: none;
                     padding:15px;
                     
                     .MuiCardMedia-root {
                            @media(max-width:768px) {
                                   display:block;
                                   margin:0 auto;
                            }
                     }
              }
       }
}