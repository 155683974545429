/******************************************************************
	Fueling Alternatives
******************************************************************/

.learn-more-modal, .learn-more-modal-no-image {
       .learn-more-text {
              font-size: 14px;
       }


       .learn-more-title {
              font-weight: 700;
              font-size: 1.5rem;
              text-transform: uppercase;
              margin-bottom: 2rem;
       }
       .learn-more-subtitle {
              font-size: 1.25rem;
              margin-bottom: 2rem;
       }
       .catalog-link-button-container {
              border:1px solid #3277B9;
              text-align: center;
              padding: 15px 0;
              position: absolute;
              bottom: 24px;
              .catalog-link-button {
                     color:#3277B9;
                     text-decoration: none;
                     font-size: 15px;
                     font-weight: 700;
              }       
       }

       .catalog-link-button-container-mobile {
              border:1px solid #3277B9;
              text-align: center;
              padding: 15px 0;
              margin-top: 1rem;

              .catalog-link-button {
                     color:#3277B9;
                     text-decoration: none;
                     font-size: 15px;
                     font-weight: 700;
              }   
       }
}

.learn-more-modal-no-image {
       .catalog-link-button-container {
              width: 710px;
              .catalog-link-button {
                     color:#3277B9;
                     text-decoration: none;
                     font-size: 15px;
                     font-weight: 700;
              }       
       }
}

.learn-more-modal {
       .catalog-link-button-container {
              width: 350px;
              .catalog-link-button {
                     color:#3277B9;
                     text-decoration: none;
                     font-size: 15px;
                     font-weight: 700;
              }       
       }
}

.fueling-alternatives-app {
       padding:0 !important;

       .learn-more-launch-modal {
              border: none;
              background-color: white !important;
              padding: 1rem 0;
              color:#3277B9;
              text-decoration: underline;
              cursor: pointer;
       }

       > div:not(.headerWrapper) {
              padding: 0 25px;
       }

       .headerWrapper {
              background-image:url("../../images/electrictVsHydrogen_header.png");
       }

       div.makeStyles-root-3, .makeStyles-root-7 {
              padding:25px;
       }

       .fueling-options-card-content {
              padding: 16px 0 0 0 !important;
       }

       .MuiCard-root {
              .MuiCardContent-root {
                     .MuiTypography-body1.MuiTypography-gutterBottom {
                            font-weight: bold;
                     }
              }
       }
}