/******************************************************************
	H2 Design
******************************************************************/

.h2-design {
       padding: 0 !important;

       .pageButtons {
              @media(max-width:768px) {
                     padding:25px !important;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
              }
       }

       .headerWrapper {
              background-image: url("../../images/design-hydrogen-banner.png");
       }

       #non-linear-slider,
       .MuiSlider-root {
              max-width: 300px;
              margin: 0 auto;
              font-weight: bold;
              display: block;
       }

       .MuiSlider-root {
              .MuiSlider-rail,
              .MuiSlider-track {
                     background-color: #3277b9;
              }
       }

       #non-linear-slider, .MuiSlider-root {
              max-width:300px;
              margin:0 auto;
              font-weight: bold;
              display: block;
       }

       .MuiSlider-root {
              .MuiSlider-rail, .MuiSlider-track {
                     background-color:#3277B9;
              }
       }

       > .MuiGrid-root {
              padding: 25px;
       }

       .MuiGrid-root {
              .MuiGrid-grid-xs-true {
                     cursor: pointer;
                     margin-right: 15px;
                     .is_selected {
                            border-top: 4px solid #3277b9;
                     }
                     .not_viable {
                            // filter:opacity(.95);
                            .h2DeliveryMethod_card_titleCheckBox,
                            .h2Design_costs_breakdown_list {
                                   color: #898989 !important;
                            }
                            img {
                                   opacity: 70;
                            }
                     }

                     .MuiPaper-root {
                            display: flex;
                            align-content: space-between;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 1em;
                            min-height: 400px;
                            padding: 15px;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            border-radius: 0px;
                            flex-direction: column;

                            &.is_selected {
                                   .MuiBox-root {
                                          color:#000;
                                   }
                            }

                            svg.MuiSvgIcon-root {
                                   width:20%;
                                   top:15px;
                                   right:15px;
                            }
                            
                            img {
                                   display:block;
                                   margin:0 auto;
                            }

                            .h2DeliveryMethod_card_titleCheckBox {
                                   display: inline-flex;
                                   .h2DeliveryMethod_card_title {
                                   }
                                   .h2DeliveryMethod_card_checkBox {
                                          margin-left: auto;
                                   }
                            }
                            .h2Design_costs_breakdown_list {
                                   width: 100%;
                                   padding: 0;
                                   margin: 20px 0 0;
                                   list-style-type: none;

                                   li {
                                          border-top: 1px solid #ccc;
                                          padding: 10px 0;

                                          .h2Design_costs_breakdown_title {
                                                 font-weight: 100;
                                                 font-size: 12px;
                                                 text-transform: uppercase;
                                                 margin: 0 0 5px;
                                          }

                                          .h2Design_costs_breakdown_value {
                                                 font-size: 14px;
                                          }
                                   }
                            }
                            .h2DeliveryMethod_card_image {
                                   margin: auto;
                            }
                     }
                     .h2DeliveryMethod_viability {
                            display: flex;
                            align-items: center;
                            .h2DeliveryMethod_viability_text {
                                   color: #787474;
                                   font-size: 0.8rem;
                                   margin: auto;
                            }
                     }
              }
       }

       .react-swipeable-view-container {
              margin-top:25px;

              .h2DeliveryMethod_card {
                     max-width:90%;
                     margin:0 auto;
                     display: flex;
                     align-content: space-between;
                     font-size: 16px;
                     font-weight: bold;
                     line-height: 1em;
                     min-height: 400px;
                     padding: 15px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
                     border-radius: 0px;
                     flex-direction: column;

                     &.is_selected {
                            border-top: 4px solid #3277b9;
                     }

                     .h2DeliveryMethod_card_titleCheckBox {
                            text-align: center;
                     }

                     img.h2DeliveryMethod_card_image {
                            margin:25px auto;
                            display:block;
                     }

                     ul.h2Design_costs_breakdown_list {
                            width: 100%;
                            padding: 0;
                            margin: 20px 0 0;
                            list-style-type: none;

                            li {
                                   text-align: center;
                                   border-top: 1px solid #ccc;
                                   padding: 10px 0;

                                   .h2Design_costs_breakdown_title {
                                          font-weight: 100;
                                          font-size: 12px;
                                          text-transform: uppercase;
                                          margin: 0 0 5px;
                                   }

                                   .h2Design_costs_breakdown_value {
                                          font-size: 14px;
                                   }
                            }
                     }
              }

              .h2DeliveryMethod_viability_text {
                     font-size:13px;
                     margin:25px 0;
                     text-align: center;
              }
       }

       .MuiMobileStepper-root {
              background-color:transparent;
              justify-content: center;
       }
}
.subtitle-text{
       font-size: 12px;
       margin: 10px 0px;
       color: #898989 !important;
       font-weight: lighter;
}