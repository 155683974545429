/******************************************************************
       Form Controls
******************************************************************/

.MuiFormControl-root {

       .MuiInput-underline:before {
              display: none;
       }

       label {
              font-weight: bold;
              font-size: 12px;
              transform: none;
       }

       p.MuiTypography-body1 {
              font-weight: bold;
              font-size:12px;
              padding:0;
              color:#626262;
       }

       .MuiInput-formControl {
              margin-top:30px;

              @media(max-width:991px) {
                     margin-top:16px;
              }
       }

       .MuiInput-root {
              .MuiInput-input {
                     border: 1px solid #DBDBDB;
                     border-radius: 4px;
                     padding:15px 10px 15px;
              }
       }

       .MuiSelect-root.MuiSelect-select {
              border: 1px solid #DBDBDB;
              border-radius: 4px;
              padding:15px 10px 15px;
              background-color: #fff;
              font-size:14px;
       }

       .MuiButtonBase-root.MuiIconButton-root {
              padding-top:0;

              .MuiSvgIcon-root {
                     width: 30px;
                     height: 30px;
                     fill:#3277B9;
                     background-color: #EBEBEB;
                     border-radius: 50px;
              }

              &:hover {
                     background-color:rgba(50, 119, 185, 0.04) !important;
              }

              .MuiFormControlLabel-label {
                     font-weight: bold !important;
              }
       }
}

.MuiSlider-root {
       height:10px !important;

       .MuiSlider-rail, .MuiSlider-track {
              height:10px;
              background-color: #E5E5E5;
              border-radius: 50px;
       }

       .MuiSlider-thumb {
              margin-top:-6px;
              width:20px;
              height:20px;
              background-color: $blue;
       }

       p.MuiTypography-root {
              font-size:14px;
              font-weight: normal;
       }
}