/******************************************************************
	EV Design
******************************************************************/
@import "../global/variables.scss";

.ev-design {
       padding: 0 !important;

       .sliderValue{
              margin-top: -36px;
              padding-bottom: 20px;
       }

       .pageButtons {
              width:calc(100% - 50px);
              margin:0 25px;
       }

       .headerWrapper {
              background-image: url("../../images/evInfrastructure_header.png");
       }

       > .MuiGrid-root {
              padding: 25px;
       }
       .is_recommendation_banner,
       .infrastructure-ev-design {
              margin: 0 25px;

              .infrastructure-ev-design {
                     margin:0;
              }
       }
       .is_recommendation_banner {
              padding-top: 8px;
              padding-bottom: 4px;
              background-color: #3277b9;
              text-align: center;
       }
       .is_recommendation_text {
              color: #fff;
              font-weight: bold;
              font: 1.6em sans-serif;
       }
 
       .infrastructure-ev-design {
              .section {
                     background-color: #fff;
                     margin-bottom: 24px;
                     padding: 24px;
                   
              }
              .ev_design_charger {
                     .title {
                            margin-bottom: 16px;
                     }
                     .static {
                            margin-top: -16px;
                     }
                     @media screen and (max-width: $medium) {
                     .non_edit{
                            padding-top: 12px;
                     }
              }
              }
              .ev_design_trenching {
                     .title {
                            margin-bottom: 16px;
                     }
                     .subTitle{
                            margin-bottom: 16px;
                     }
                     .description{
                            font-weight: 100 !important;    
                            .value{
                                   font-weight: bold;
                            }
                        }
              }

              .totalCapacityValue{
                     display: flex;
                     align-items: center;
                     padding-top:10px;

                     @media(max-width:991px) {
                            padding-top: 0px !important;
                     }

                     .MuiTypography-root.MuiTypography-body1 {
                            font-size: 20px;
                     }

                     .kw{
                            font-weight: 100;
                            margin-right: 40px;
                     }

                     .editHolder{
                            margin-left: auto;
                            padding: 0 16px;
                            border-left: 1px solid ;                     
                     }
                     Button{
                            text-transform: capitalize;
                            color: #3277B9
                     }
                     .cancelButton{
                            color: #acacb9
                     }
              }

              p.MuiTypography-root.MuiTypography-body1 {
                     text-transform: uppercase;
                     font-size: 14px;
                     font-weight: 600;
              }

              .MuiFormControl-root {
                     label {
                            font-size: 14px;
                            color: #000;
                     }
              }

              .MuiGrid-root {
                     p.MuiTypography-root.MuiTypography-body1 {
                            text-transform: none;
                     }
              }
       }
}
