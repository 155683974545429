.equipment-catalog{
    padding: 0 !important;

       .headerWrapper {       
              background-image: url("../../images/header_equipment-catalog.png");       
       }

       > .MuiGrid-root {
              padding: 25px;
       }

       .electric-catalog-legend {
              margin-top:50px;
              margin-bottom: 24px;
              padding-left: 16px;

              .legend-item {
                     display: flex;

                     .legend-item-text {
                            display: flex;
                            align-items: center;
                     }
              }
       }

       .tabs-bar {
              background-color: transparent;
              box-shadow: none;

              .full-width-tabs {
                     display:block;
                     margin:0 auto;

                     .PrivateTabIndicator-colorPrimary-4 {
                            display:none;
                     }

                     .MuiButtonBase-root {
                            background-color: #fff;
                            border:1px solid #3277B9;

                            span {
                                   color:#2A2A2A;
                                   text-transform: uppercase;
                                   font-weight: 400;
                                   font-size: 16px;
                            }

                            &.Mui-selected {
                                   background-color:#3277B9;

                                   span {
                                          color:#fff;
                                          font-weight: bold;
                                   }
                            }
                     }
              }
       }

       .MuiFormControl-root {
              width:100%;

              .MuiInput-root {
                     margin-top:20px;

                     select {
                            border:1px solid #3277B9;

                     }
              }
       }

       .MuiAccordion-root {
              margin:50px 0;
       }

       .MuiGrid-container.MuiGrid-spacing-xs-3 {
              .MuiGrid-item .catalog_card {
                     height: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;

                     .MuiCardHeader-root {
                            padding:10xpx 16px;
                            p.equip_make {
                                   color:#505357;
                                   font-size:14px;
                            }

                            p.equip_model {
                                   color:#2B2B2B;
                                   font-size:25px;
                                   font-weight: bold;
                            }
                     }

                     .above_photo_text {
                            padding:0 16px;

                            p.charger_power {
                                   color:#505357;
                                   font-size:14px;
                            }

                            p.charger_price {
                                   color:#2B2B2B;
                                   font-size:20px;
                                   font-weight: bold;
                            }
                     }

                     .equip_photo {
                            width:auto;
                            display:block;
                            margin:0 auto;
                     }

                     .equip_variant {
                            height: 1.5rem;
                     }

                     .charger_card_content {
                            padding:0 16px 16px;

                            .checked_item {
                                   display:flex;
                                   margin-bottom: 15px;
                                   min-height: 1.5rem;

                                   svg {
                                          margin-right:15px;
                                          fill:orange;
                                   }

                                   p {
                                          color:#505357;
                                          font-size:16px
                                   }
                            }

                            .catalog_card_button {
                                   width:100%;
                                   background-color: #fff;
                                   box-shadow: none;
                                   border:1px solid #3277B9;
                                   color:#3277B9;
                                   text-transform: none;
                                   font-size:16px;
                                   border-radius: 0px;
                                   padding:10px 0;

                                   // position: absolute;

                                   .MuiButton-label {
                                          a {
                                                 color:#3277B9;

                                                 &:hover {
                                                        text-decoration: none;
                                                 }
                                          }
                                   }
                            }
                     }
              }
       }
}