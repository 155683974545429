@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

.close-button {
    position: absolute;
    top: 5px;
    right: 0;
}

#loginIntoYourAccount,
#createAnAccount,
#verifyYourEmail,
#forgotPassword,
#newPasswordDialog {
    padding:25px;

    .MuiLink-underlineHover{
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
    }

    .dont_have_account {
        text-align: right;

        span {
            font-size:14px;
        }
    }

    .headline {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        color: #626262;
        font-family: "Montserrat", sans-serif !important;
    }

    .error-message {
        color: red
    }

    .hidden-error-message {
        display: none
    }

    button {
        background-color: #3277b9;
        color: #fff;
        text-decoration: none !important;
        font-size: 14px;
        font-weight: 600;
        padding:6px 25px;
        border: 1px solid #3277b9;
        box-shadow: none !important;
        border-radius: 0%;

        .MuiButton-label {
            text-transform: none;
        }
    }

    .already_have_account {
        margin-bottom: 20px;

        span {
            font-size:14px;
        }
    }

    .forgot_password {
        float:right;
        text-decoration: underline;
        color:#626262;
        font-weight: 100;
        font-size:14px;
    }

    .password_reset_submit_button {
        @media screen and (min-width: $medium) {
            margin-top: 24px;
        }
    }

    .MuiFormControl-root .MuiInput-formControl {
        margin-top:20px;
    }

    .MuiFormGroup-root {
        span.MuiFormControlLabel-label {
            a {
                position: relative;
                top:-5px;
                font-weight:100;
                font-size:14px;
                color:#626262;
            }
        }
    }
}


#forgotPassword {
    .dont_have_account {
        text-align: left;
    }
}