/******************************************************************
	Cost Electric
******************************************************************/

.ev-costs {

       .evse-costs-data {
              font-style: italic;
       }

       .desktop-summary-title {
              font-weight: bold;
              font-size: 1rem !important;
       }

       .mobile-summary-cost {
              text-align: right;
       }

       .mobile-summary-title, .mobile-summary-cost {
              font-weight: bold;
              font-size: 1rem;
       }

       .mobile-summary-title-container, .mobile-summary-cost-container {
              padding-top: 0 !important;
              padding-bottom: 0 !important;
       }

       .mobile-summary-title-container {
              padding-left: 8px !important;
       }

       .mobile-summary-cost-container {
              padding-right: 8px !important;
       }

       .mobile-summary-text-container {
              padding: 0 8px 0 8px !important;
       }

       .mobile-hardware-recap {
              padding: 0 8px 0 8px
       }

       .mobile-total {
              padding: 15px !important;

              .total-title, .total-amount {
                     .MuiTypography-root {
                            font-size: 20px;
                            font-weight: bold;              
                     }
              }
              .total-amount {
                     text-align: right;
              }
              .disclaimer {
                     margin-top: 16px;
              }
       }

       .MuiBox-root {
              .MuiBox-root {


                     &:nth-child(1).desktop-summary {
                            margin-bottom: 24px;
                     }
                     &:nth-child(1).mobile-summary {
                            margin-bottom: 12px;
                     }
                     &:nth-child(1) {
                            background-color: #000046;
                            padding:10px;
                            margin-bottom: 36px;

                            .MuiBox-root {
                                   margin-bottom: 0px;
                            }

                            .MuiGrid-root {
                                   justify-content: space-between;

                                   .MuiGrid-root {
                                          &:last-child {
                                                 svg {
                                                        display:none;
                                                 }
                                          }

                                          .MuiBox-root {
                                                 padding:5px;
                                                 -webkit-box-sizing: border-box;
                                                 -moz-box-sizing: border-box;
                                                 box-sizing: border-box;

                                                 p.MuiTypography-root.MuiTypography-body1 {
                                                        font-size:14px;
                                                 }

                                                 svg {
                                                        position: relative;
                                                        top: 50%;
                                                        -ms-transform: translateY(-50%);
                                                        transform: translateY(-50%);
                                                 }
                                          }
                                   }
                            }
                     }

                     &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                            padding:8px;
                            margin-bottom: 25px;

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   &:nth-child(1) {
                                          border-bottom: 1px solid #ccc;
                                          margin-bottom: 8px;

                                          .MuiGrid-root.MuiGrid-item.price-range {
                                                 h3.MuiTypography-root.MuiTypography-h3 {
                                                        color:#3277B9;
                                                        padding-right:8px;
                                                        text-align: right;
                                                 }
                                          }
                                   }

                                   &:nth-child(2) {
                                          .MuiGrid-root:nth-child(4) {
                                                 p {
                                                        text-align: right;
                                                        font-weight: bold;
                                                        font-size:16px;
                                                        padding-right: 8px;
                                                 }
                                          }
                                   }
                            }

                            .MuiTypography-root.MuiTypography-h3 {
                                   text-transform: uppercase;
                                   font-size:20px;
                                   font-weight: bold;
                                   margin-bottom: 4px;
                                   padding:8px 0 0 8px;
                            }

                            // .MuiTypography-root.MuiTypography-h5 {
                            //        margin-bottom: 10px;
                            // }

                            p.MuiTypography-root.MuiTypography-body1 {
                                   font-size:14px;;
                            }

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3 {
                                   .MuiTypography-root.MuiTypography-h3 {
                                          text-transform: none;
                                          text-align: right;
                                   }
                            }

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   &:nth-child(2) {
                                          .left-tip-mobile {
                                                 padding-left: 12px;
                                          }

                                          .right-tip-mobile {
                                                 padding-right: 12px;
                                                 text-align: right;
                                          }


                                          .MuiGrid-item {
                                                 &:nth-child(1) {
                                                        .MuiBox-root {
                                                               background-color:white;
                                                        }
                                                 }

                                                 &:nth-child(2),&:nth-child(3) {
                                                        .tip-card-desktop {
                                                               margin-top:36px;
                                                        }

                                                        .tip-card-mobile {
                                                               margin-bottom: 12px;
                                                               min-height: 130px;
                                                        }

                                                        .MuiPaper-root {
                                                               background-color: #E5E5E5;
                                                               padding:15px;
                                                               box-shadow: none;
                                                               border-radius: 0px;

                                                               .MuiTypography-root.MuiTypography-body1:nth-child(1) {
                                                                      font-weight: bold;
                                                                      text-transform: uppercase;
                                                                      color:#000046;
                                                                      margin-bottom: 5px;
                                                               }

                                                               .MuiTypography-root.MuiTypography-body1:nth-child(2) {
                                                                      font-size:14px;
                                                               }
                                                        }
                                                 }

                                                 &:nth-child(5) {
                                                        .MuiTypography-root.MuiTypography-body1 {
                                                               font-weight: bold;
                                                               font-size:20px;
                                                               text-align: right;
                                                        }
                                                 }
                                          }
                                   }
                            }
                     }

                     &:nth-child(6) {
                            padding-bottom: 0px;

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   border-bottom: 1px solid #ccc;
                                   margin-bottom: 15px;

                                   &:last-child {
                                          border-bottom: none;
                                          margin-bottom: 0px;
                                   }

                                   .MuiBox-root {
                                          margin-bottom: 0px;
                                          background-color: transparent;

                                          .MuiTypography-root.MuiTypography-h5 {
                                                 font-weight: bold;
                                                 font-size:18px;
                                          }
                                   }

                                   .MuiGrid-root {
                                          .MuiTypography-root.MuiTypography-body1 {
                                                 // text-align: right;
                                                 // font-weight: bold;
                                          }
                                   }
                            }
                     }

                     &:nth-child(6) {
                            background-color: #000046;
                            margin-bottom: 25px;
                            padding:25px;

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9 {
                                   .MuiTypography-root.MuiTypography-h5 {
                                          margin:0 0 5px;
                                          font-weight: bold;
                                   }

                                   .MuiTypography-root.MuiTypography-body1 {
                                          font-size:14px;
                                   }
                            }

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3 {
                                   .MuiTypography-root.MuiTypography-h5 {
                                          text-align: right;
                                          font-weight: bold;
                                   }
                            }
                     }
              }
       }
}