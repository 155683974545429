/******************************************************************
	Modal
******************************************************************/

.MuiDialog-container {

       .MuiPaper-root {
              max-width:350px;
              padding:25px;
       }

       img {
              display:block;
              margin:0 auto 50px;
       }

       h2 {
              font-family: "Montserrat", sans-serif !important;
              text-transform: uppercase;
              text-align: center;
              font-weight: bold;
              font-size: 20px;
              line-height: 1em;
       }

       p {
              text-align: center;
              font-size:14px;
              color:#000;
       }

       a {
              display:block;
              background-color: $blue;
              color:#fff;
              width:100%;
              text-decoration: none;
              text-align: center;
              padding:15px;
       }
}