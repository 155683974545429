/******************************************************************
       Print Styles Here
******************************************************************/

@media print {
       nav.navigation-before-content {
              a, .MuiPaper-root  {
                     display:none !important;
              }

              a[aria-current='page'] {
                     display:block !important;
                     margin:0;
              }
       }

       * {
              background-color: transparent !important;
              color:black;
       }

       .ev-costs .MuiBox-root .MuiBox-root:nth-child(1) {
              margin-bottom: 200px;
       }


       // Custom
       .ev-costs .MuiBox-root .MuiBox-root:nth-child(1).desktop-summary {
              margin-bottom: 0px;
       }

       .ev-costs .MuiBox-root .MuiBox-root:nth-child(2) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(2) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(2) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(3) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(3) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(2) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(3) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(3) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(4) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(2) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(4) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(3) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(5) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(2) .tip-card-desktop, .ev-costs .MuiBox-root .MuiBox-root:nth-child(5) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-item:nth-child(3) .tip-card-desktop {
              margin-top: 0;
       }

       .ev-costs .MuiBox-root .MuiBox-root:nth-child(2) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-root:nth-child(4) p, .ev-costs .MuiBox-root .MuiBox-root:nth-child(3) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-root:nth-child(4) p, .ev-costs .MuiBox-root .MuiBox-root:nth-child(4) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-root:nth-child(4) p, .ev-costs .MuiBox-root .MuiBox-root:nth-child(5) .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center:nth-child(2) .MuiGrid-root:nth-child(4) p {
              display:none;
       }

       .h2-costs .cost_estimate_details .MuiPaper-root .description_card_array .labor-tip-cards-cost, .h2-costs .cost_estimate_details .MuiPaper-root .description_card_array .permit-fees-tip-cards-cost {
              display:none;
       }
}