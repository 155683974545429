/******************************************************************
	Typography
******************************************************************/

// Open Sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap');

body {
       font-family: 'Open Sans', sans-serif;
}

.MuiFormLabel-root, .MuiTypography-body1 {
       font-family: 'Open Sans', sans-serif !important;
}

.makeStyles-heading-2 {
       font-weight: bold !important;
}