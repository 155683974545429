/******************************************************************
	WorkPlan SMR
******************************************************************/

.h2-workplan-smr {
       .MuiBox-root {
              .MuiBox-root {
                     &:nth-child(1) {
                            background-color: #000046;
                            padding:10px;
                            margin-bottom: 25px;

                            .MuiBox-root {
                                   margin-bottom: 0px;
                            }
                     }

                     &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                            padding:25px;
                            margin-bottom: 25px;

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   .MuiGrid-root {
                                          margin-top:0;
                                   }
                            }

                           .MuiGrid-root {
                                   /* Temporary */
                                   .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2 {
                                          overflow: hidden;
                                   }

                                  .MuiBox-root {
                                          background-color: transparent;
                                          margin:0;
                                          padding:0;

                                          .MuiTypography-root.makeStyles-alignLeft-2.MuiTypography-h3 {
                                                 font-size:28px;
                                                 text-transform: uppercase;
                                                 font-weight: bold;
                                                 margin:0 0 10px;
                                          }

                                          .MuiTypography-root.makeStyles-alignLeft-2.MuiTypography-h4 {
                                                 font-size:14px;
                                                 text-decoration: underline;
                                                 color:#07679B;
                                          }
                                  }

                                  .MuiTypography-root.makeStyles-alignRight-3.MuiTypography-h3 {
                                          font-weight: 400;
                                          font-size:25px;
                                   }

                                   .MuiGrid-root {
                                          margin:30px 0 0;
                                          padding:20px 10px;

                                          &:last-child {
                                                 .MuiCard-root {
                                                        border-right:none;
                                                 }
                                          }

                                          .MuiCard-root {
                                                 border-right: 1px solid #ccc;
                                                 box-shadow: none;
                                                 border-radius: 0px;

                                                 .MuiTypography-root.MuiTypography-body1 {
                                                        &:nth-child(1) {
                                                               padding-top:20px;
                                                               margin-bottom: 10px;
                                                        }

                                                        &:nth-child(2) {
                                                               padding-bottom:20px;
                                                        }
                                                 }
                                          }
                                   }
                           }
                     }
              }
       }
}