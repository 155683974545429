/******************************************************************
	H2 Costs
******************************************************************/

.h2-costs {
       display: flex;
       flex-wrap: wrap;
       
       .tco_top_summary {
              justify-content: space-between;
              background-color: #000046;
              padding: 10px;
              width:100%;
              margin:0;

              .costs_chevron {
                     position: relative;
                     top: 50%;
              }

              .MuiGrid-root {
                     p.TopBar-title {
                            color: #fff;
                            font-size: 1.5rem;
                     }
                     p.TopBar-cost {
                            color: #fff;
                            font-size: 14px;
                     }

                     svg {
                            fill: #fff;
                     }
              }
       }

       .tco_mobile {
              padding: 20px;

              .MuiGrid-root {
                     p.TopBar-cost {
                            font-size: 1.5rem;
                            text-align: right;
                     }
                     p.TopBar-disclaimer {
                            margin-top: 1rem;
                            color: #fff;
                     }
              }             
       }

       > .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3 {
              background-color: #000046;
              padding: 20px;

              p {
                     color: #fff;
              }
       }

       .cost_estimate_details {
              width: 100%;
              margin:50px 0 0;

              .MuiPaper-root {
                     width: 100%;
                     border-radius: 0px;
                     margin-bottom: 30px;
                     padding: 15px;

                     p.MuiTypography-root.title {
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 20px;
                     }

                     p.MuiTypography-root.title_cost, p.MuiTypography-root.bottom_bar_title_cost {
                            text-align: right;
                            font-weight: bold;
                            font-size: 20px;
                     }

                     p.MuiTypography-root.title_cost {
                            color:#3277B9;
                     }

                     .engineering_and_design,
                     .permitting_and_fees {
                            .description_card_array {
                                   .MuiGrid-root {
                                          &:nth-child(2),
                                          &:nth-child(3) {
                                                 p {
                                                        padding: 5px 15px;
                                                        font-size: 14px;
                                                        font-weight: normal;
                                                        background-color: #f0f3fc;
                                                 }
                                          }
                                   }
                            }
                     }

                     .hardware_and_installation {
                            .MuiGrid-root:nth-child(3){
                                   width:100%;
                            }

                            .MuiGrid-root {
                                   &.MuiGrid-grid-xs-4 {
                                          p {
                                                 text-align: right;
                                                 font-weight: bold;
                                          }
                                   }
                            }

                            .description_card_array {
                                   .MuiGrid-root {
                                          border-bottom: 1px solid #ccc;
                                   }
                            }
                     }

                     &.total_project_cost_paper {
                            background-color: #000046;

                            p {
                                   color: #fff;
                            }
                     }

                     .description_card_array {
                            border-top: 1px solid #ccc;


                            .tip-card-desktop {
                                   margin-top: 20px !important;
                            } 

                            .MuiGrid-root {
                                   margin: 4px 0px 0;

                                   p.labor-tip-cards-title, p.permit-fees-tip-cards-title {
                                          font-weight: bold !important;
                                          font-size: 16px;
                                   }

                                   p.labor-tip-card-description, p.permit-fees-tip-card-description {
                                          font-style: italic;
                                          font-size: 14px;
                                   }

                                    

                                   &:last-child {
                                          p {
                                                 text-align: right;
                                                 font-weight: bold;
                                          }
                                   }
                            }

                            .labor-tip-cards-description-container-mobile, .permit-fees-tip-cards-description-container-mobile  {
                                   margin-top: 0px;
                            }


                            p.labor-tip-cards-title-mobile, p.permit-fees-tip-cards-title-mobile {
                                   font-weight: bold;
                            }

                            p.labor-tip-cards-description-mobile, p.permit-fees-tip-cards-description-mobile {
                                   font-style: italic;
                                   background-color: inherit !important;
                                   text-align: left !important;
                                   padding: 0 !important;
                            }

                            p.labor-tip-cards-cost-mobile, p.permit-fees-tip-cards-cost-mobile {
                                   background-color: inherit !important;
                                   text-align: right !important;
                                   font-weight: bold !important;
                                   padding: 0 !important;
                            }
                     }
              }
       }
}
