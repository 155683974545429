/******************************************************************
	Filters Buttons
******************************************************************/

#additional-actions1-content {
       .MuiInputLabel-formControl {
              position: relative;
              top:-7px;
       }

       .additional-filter-buttons {
              .MuiButtonBase-root {
                     padding:10px 35px;
                     font-size: 13px;
                     font-weight: 500;
                     border:1px solid #3277B9;
       
                     &.isActive, &:hover {
                            background-color:#3277B9;
                            color:#fff;
                     }
              }
       }
}