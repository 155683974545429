/******************************************************************
	Utility Lookup
******************************************************************/

.utility-lookup {
       padding:0 !important;
       overflow: hidden;
     
       .headerWrapper {
              background-image:url("../../images/utilityLookup_header.png");
       }

       .utility_lookup_grid{
              margin-top: 24px;
              margin-bottom: 24px;
       }
       div.makeStyles-root-3 {
              padding:25px;
       }

 
       .supplier_logo{
               max-width: 120px;
               padding: 4px;
       }
 
       .supplier_content{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding:8px;
              border-radius: 0px;

              @media(max-width:991px) {
                     max-width: 90%;
                     margin: 0 auto;
                     flex-wrap: wrap;
              }
        }

        .supplier_content_text_div,
        .supplier_content_image_div,
        .supplier_content_button_div{
               padding-top:20px;
               padding-bottom:20px;
               min-width:10%;

               @media(max-width:991px) {
                     width:100%;
                     justify-content: center;
                     display: flex;
               }
         }

         .supplier_content_text_div{
              min-width: 30%;
         }
         
         .supplier_content_image_div{}
         .supplier_content_text_div{
              margin-left: 5%;
              padding-right: 20%;
              padding-left: 5%;

              @media(max-width:991px) {
                     margin-left:0;
                     padding-right: 5%;
               }
         }


       .supplier_content_button{
              border:solid 1px $blue;
              border-radius: 0px;
              padding:10px 20px;

              span.MuiButton-label {
                     a {
                            color:$blue;
                     }
              }
        }
       .utlity_submit_button{
              background-color: $blue;
              color: white; 
              padding:14px 30px;
              border-radius: 0px;

              @media(max-width:500px) {
                     margin:0 25px;
                     width:calc(100% - 50px);
              }

              &:hover{
                     color: $blue;
                     background-color:#fff;
              }
       }
       .utility_text_field{
              width:100%;

              .MuiOutlinedInput-root {
                     margin:0 auto;
                     display:block;
                     background-color:#fff;
                     
                     @media(max-width:500px) {
                            margin:0 25px;
                            width:calc(100% - 50px);
                     }
              }
       }

       .MuiCard-root {
              .MuiCardContent-root {
                     .MuiTypography-body1.MuiTypography-gutterBottom {
                            font-weight: bold;
                     }
              }
       }
}