/******************************************************************
	Cost Electrolysis
******************************************************************/

.h2-costs-electrolysis {
       .MuiBox-root {
              .MuiBox-root {
                     &:nth-child(1) {
                            background-color: #000046;
                            padding:10px;
                            margin-bottom: 25px;

                            .MuiBox-root {
                                   margin-bottom: 0px;
                            }
                     }

                     &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                            padding:25px;
                            margin-bottom: 25px;

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   &:nth-child(1) {
                                          border-bottom: 1px solid #ccc;
                                          margin-bottom: 25px;
                                   }
                            }

                            .MuiTypography-root.MuiTypography-h3 {
                                   text-transform: uppercase;
                                   font-size:25px;
                                   font-weight: bold;
                                   margin-bottom: 15px;
                            }

                            .MuiTypography-root.MuiTypography-h5 {
                                   margin-bottom: 10px;
                            }

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3 {
                                   .MuiTypography-root.MuiTypography-h3 {
                                          text-transform: none;
                                          text-align: right;
                                   }
                            }

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   &:nth-child(2) {
                                          .MuiGrid-item {
                                                 &:nth-child(1) {
                                                        .MuiBox-root {
                                                               background-color:white;
                                                        }
                                                 }

                                                 &:nth-child(2),&:nth-child(3) {
                                                        .MuiPaper-root {
                                                               background-color: #F0F3FC;
                                                               padding:15px;
                                                               box-shadow: none;
                                                               border-radius: 0px;
                                                               margin-top:50px;

                                                               .MuiTypography-root.MuiTypography-body1:nth-child(1) {
                                                                      font-weight: bold;
                                                                      text-transform: uppercase;
                                                                      color:#000046;
                                                                      margin-bottom: 5px;
                                                               }
                                                        }
                                                 }

                                                 &:nth-child(4) {
                                                        .MuiTypography-root.MuiTypography-body1 {
                                                               font-weight: bold;
                                                               font-size:20px;
                                                               text-align: right;
                                                        }
                                                 }
                                          }
                                   }
                            }
                     }

                     &:nth-child(4) {
                            padding-bottom: 0px;

                            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-justify-content-xs-center {
                                   border-bottom: 1px solid #ccc;
                                   margin-bottom: 15px;

                                   &:last-child {
                                          border-bottom: none;
                                          margin-bottom: 0px;
                                   }

                                   .MuiBox-root {
                                          margin-bottom: 0px;
                                          background-color: transparent;

                                          .MuiTypography-root.MuiTypography-h5 {
                                                 font-weight: bold;
                                                 font-size:18px;
                                          }
                                   }

                                   .MuiGrid-root {
                                          .MuiTypography-root.MuiTypography-body1 {
                                                 text-align: right;
                                                 font-weight: bold;
                                          }
                                   }
                            }
                     }

                     &:nth-child(5) {
                            background-color: #000046;
                            margin-bottom: 25px;
                            padding:25px;

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9 {
                                   .MuiTypography-root.MuiTypography-h5 {
                                          margin:0 0 5px;
                                          font-weight: bold;
                                   }

                                   .MuiTypography-root.MuiTypography-body1 {
                                          font-size:14px;
                                   }
                            }

                            .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3 {
                                   .MuiTypography-root.MuiTypography-h5 {
                                          text-align: right;
                                          font-weight: bold;
                                   }
                            }
                     }
              }
       }
}