.vendor-catalog{
       padding: 0 !important;

       .headerWrapper {       
              background-image: url("../../images/header_vendor-catalog.png");       
       }

       > .MuiGrid-root {
              padding: 25px;
       }

       .tabs-bar {
              background-color: transparent;
              box-shadow: none;

              .full-width-tabs {
                     display:block;
                     margin:0 auto;

                     .PrivateTabIndicator-colorPrimary-4 {
                            display:none;
                     }

                     .MuiButtonBase-root {
                            background-color: #fff;
                            border:1px solid #3277B9;

                            span {
                                   color:#2A2A2A;
                                   text-transform: uppercase;
                                   font-weight: 400;
                                   font-size: 16px;
                            }

                            &.Mui-selected {
                                   background-color:#3277B9;

                                   span {
                                          color:#fff;
                                          font-weight: bold;
                                   }
                            }
                     }
              }
       }

       .vendor_catalog_controls {
              margin-bottom:50px;

              > .MuiTypography-root.MuiTypography-body1 {
                     font-weight:bold;
                     font-size:12px;
                     margin-bottom: 20px;
                     left:5px;
                     position: relative;
              }

              .MuiGrid-container {
                     padding:0 16px;
                     justify-content: flex-start;

                     @media(max-width:991px) {
                            flex-wrap: wrap;
                     }

                     .MuiGrid-item {
                            padding:0;

                            @media(max-width:991px) {
                                   width:100%;
                                   max-width: 100%;
                                   margin-left:0;
                                   flex-basis: 100%;
                                   margin-bottom: 10px;
                            }

                            &.MuiGrid-grid-md-6 {
                                   margin-left:auto;
                            }

                            .MuiFormControl-root {
                                   width:98%;

                                   .MuiButton-root {
                                          width:100%;
                                          background-color: #fff;
                                          border:1px solid #3277B9;
                                          border-radius: 0px;
                                          padding:10px 20px;
                                          text-transform: none;
                                          font-size:12px;

                                          &.isActive {
                                                 background-color:#3277B9;
                                                 color:#fff;
                                          }
                                   }
                            }

                            .vendor_sorter_dropdown {
                                   .MuiFormControl-root {
                                          .MuiInput-formControl {
                                                 margin-top:0px;

                                                 select {
                                                        min-width:150px;
                                                        border:1px solid #3277B9;
                                                 }
                                          }

                                          span.MuiTypography-root {
                                                 font-size:12px;
                                                 text-transform: uppercase;
                                                 margin-right:10px;
                                          }
                                   }
                            }
                     }
              }
       }

       .catalog_card {
              display:flex;
              justify-content: space-between;
              flex-direction: column;
              height:100%;
              border-top:5px solid #3277B9;
              border-radius: 0px;

              &.construction_class {
                     border-top:5px solid #F7941D;
              }

              .MuiCardHeader-root {
                     p {
                            font-size:14px;
                            text-transform: uppercase;
                     }
              }

              .vendor_logo {
                     max-width:90%;
                     width:auto;
                     max-height:200px;
                     display:block;
                     margin:0 auto;
              }

              .charger_card_content {
                     p {
                            text-align: center;
                     }

                     .catalog_card_button {
                            width:100%;
                            background-color: #fff;
                            box-shadow: none;
                            border:1px solid #3277B9;
                            color:#3277B9;
                            text-transform: none;
                            font-size:16px;
                            border-radius: 0px;
                            padding:10px 0;

                            .MuiButton-label {
                                   a {
                                          color:#3277B9;

                                          &:hover {
                                                 text-decoration: none;
                                          }
                                   }
                            }
                     }
              }
       }
}