/******************************************************************
	Pages
******************************************************************/
@import "./home.scss";
@import "./guided-planner.scss";
@import "./fueling-alternatives.scss";
@import "./ev-design.scss";
@import "./cost-electric.scss";
@import "./cost-gas.scss";
@import "./cost-tube.scss";
@import "./cost-estimates.scss";
@import "./cost-electrolysis.scss";
@import "./cost-smr.scss";
@import "./ev-workplan.scss";
@import "./workplan-tube.scss";
@import "./workplan-gas.scss";
@import "./workplan-liquid.scss";
@import "./workplan-electrolysis.scss";
@import "./workplan-smr.scss";
@import "./h2-design.scss";
@import "./h2-workplan.scss";
@import "./h2-costs.scss";
@import "./utility-lookup.scss";
@import "./equipment-catalog.scss";
@import "./vendor-catalog.scss";
@import "../components/login_modal.scss";




/************************************************
	Global Styles
*************************************************/
.headerWrapper {       
	margin-bottom: 1.5rem;
	h2 {}
}